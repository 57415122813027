/* eslint-disable */
import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
import FaceBookIcon from "../assets/img/facebook.png";
import GoogleIcon from "../assets/img/google.png";
import { LOGIN_API_URL, SIGN_UP_API_URL, FORGOT_PASSWORD_API_URL, SOCIAL_FIREBASE_API_URL } from "./Shared/constant";
import { useDispatch } from "react-redux";
import { checkLogin, getUser, getAccessToken } from "../redux/actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {auth,provider} from "../config/firebaseConfig";
import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import InfoIcon from "../assets/img/information-button.png";

const LoginSignupModal = (props) => {
    const { setLoaderStart } = props;
    const dispatch = useDispatch();
    const [ LoginSignupSet, setLoginSignupSet ] = useState("Login");
    const [ loginWithForm, setLoginWithForm ] = useState(false);
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    const PasswordRegex = /^(?=.*[0-9])(?=.*[!@$%^&*])[a-zA-Z0-9!@$%^&*]{6,16}$/;
    const [ showPassword, setShowPassword ] = useState(false);

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");

    // signup page function
    const signupFunction = () => {
        setLoginSignupSet("SignUp");
        setLoginWithForm(false);
        ClearFields();
    };

    // login page function
    const loginFunction = () => {
        setLoginSignupSet("Login");
        setLoginWithForm(false);
        ClearFields();
    };

    // forgot password page function
    const forgotpassFunction = () => {
        setLoginSignupSet("Forgotpassword");
        setLoginWithForm(false);
    }

    // login function button
    const LoginInBtn = () => {
        if(email !== "" && password !== "") {
            if(EmailRegex.test(email) === true && PasswordRegex.test(password) === true) {
                const jsonData = JSON.stringify({ email: email.toLowerCase(), password: password});
                window?.axios?.post(LOGIN_API_URL, jsonData, {
                    headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                    }
                }).then(function (result) {
                    getLogindata(result);
                })  
                .catch(function (result) {
                    getLoginerror(result);
                });
                setErrorEmail("");
                setErrorPassword("");
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }
                if(PasswordRegex.test(password) === false) {
                    setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
                } else {
                    setErrorPassword("");
                }
            }
        } else {
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
            if (password === "") {
                setErrorPassword("Password cannot be empty");
            } else if(PasswordRegex.test(password) === false) {
                setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
            } else {
                setErrorPassword("");
            }
        }
    };


    const getLogindata = (result) => {
        dispatch(getUser(result?.data?.data?.user));
        dispatch(getAccessToken(result?.data?.data?.access_token));
        dispatch(checkLogin("yes"));
        toast.success(result.data.msg);
        ClearFields();
        document.getElementById("appbody").classList.remove("loginModal");
        if(window.innerWidth < 800) {
            var element = document.getElementById("responsiveSidebarMain");
            element.classList.remove("active");
        }
        setLoaderStart(false);
        setLoginSignupSet("Login");
        setLoginWithForm(false);
    }

    const getLoginerror = (result) =>{
        dispatch(getUser(""));
        dispatch(checkLogin("no"));
        dispatch(getAccessToken(""));
        toast.error(result.response.data.error);
    }

    // signup function button
    const SignUpBtn = () => {
        if(email !== "" && password !== "") {
            if(EmailRegex.test(email) === true && PasswordRegex.test(password) === true) {
                const jsonData = JSON.stringify({ email: email.toLowerCase(), password: password});
                window.axios.post(SIGN_UP_API_URL, jsonData, {
                    headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                    }
                }).then(function (result) {
                    dispatch(getUser(result?.data?.data?.user));
                    dispatch(getAccessToken(result?.data?.data?.access_token));
                    dispatch(checkLogin("yes"));
                    document.getElementById("appbody").classList.remove("loginModal");
                    toast.success(result.data.msg);
                    ClearFields();
                })  
                .catch(function (result) {
                    dispatch(getUser(""));
                    dispatch(checkLogin("no"));
                    dispatch(getAccessToken(""));
                    toast.error(result.response.data.error);
                });
                setErrorEmail("");
                setErrorPassword("");
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }
                if(PasswordRegex.test(password) === false) {
                    setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
                } else {
                    setErrorPassword("");
                }
            }
        } else {
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
            if (password === "") {
                setErrorPassword("Password cannot be empty");
            } else if(PasswordRegex.test(password) === false) {
                setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
            } else {
                setErrorPassword("");
            }
        }
    };

    // create new password function button
    const ForgotPasswordFnct = () => {
        if(email !== "") {
            if(EmailRegex.test(email) === true) {
                setLoaderStart(true);
                const jsonData = JSON.stringify({ email: email.toLowerCase() });
                window.axios.post(FORGOT_PASSWORD_API_URL, jsonData, {
                    headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                    }
                }).then(function (result) {
                    document.getElementById("appbody").classList.remove("loginModal");
                    toast.success(result.data.msg);
                    setLoginSignupSet("Login");
                    setLoaderStart(false);
                    ClearFields();
                })  
                .catch(function (result) {
                    setLoaderStart(false);
                    toast.error(result.response.data.error);
                });
                setErrorEmail("");
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }
            }
        } else {
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
        }
    };

    // clear field set
    const ClearFields = () => {
        setTimeout(() => {
            setEmail(""); 
            setPassword("");
            setErrorEmail(""); 
            setErrorPassword("");
        }, 500);
    };

    // close login modal
    const closeLoginSignupModal = () => {
        document.getElementById("appbody").classList.remove("loginModal");
        setLoginSignupSet("Login");
        setLoginWithForm(false);
    };

    // sign up or login with google 
    const authWithgoogle = (e) => {
        e.preventDefault();
        setLoaderStart(true);
        signInWithPopup(auth,provider).then((data)=>{
            window.axios.get(SOCIAL_FIREBASE_API_URL + data?.user?.uid, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
                }).then(function (result) {
                    getLogindata(result);
                    setLoaderStart(false);
                })  
                .catch(function (result) {
                    getLoginerror(result);
                    setLoaderStart(false);
                });
        }).catch(function(error) {
            if (error.code === "auth/popup-closed-by-user") {
                setLoaderStart(false);
            };
        });
    };

     // sign up or login with facebook
     const authWithfacebook = (e) => {
        e.preventDefault();
        const provider = new FacebookAuthProvider();
        signInWithPopup(auth,provider).then((data)=>{
            window.axios.get(SOCIAL_FIREBASE_API_URL + data?.user?.uid, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
                }).then(function (result) {
                    getLogindata(result);
                })  
                .catch(function (result) {
                    getLoginerror(result);
                });
        }).catch(function(error) {
            if (error.code === "auth/popup-closed-by-user") {
                setLoaderStart(false);
            };
        });
    }

    return(<div className="loginsignupModals">
        <button className="closeModal" onClick={() => closeLoginSignupModal()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><filter id="close_svg__a" width="136.7%" height="135.5%" x="-18.3%" y="-17.8%" filterUnits="objectBoundingBox"><feMorphology in="SourceAlpha" operator="dilate" radius="9" result="shadowSpreadOuter1"></feMorphology><feOffset dx="2" dy="12" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="14"></feGaussianBlur><feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix><feMerge><feMergeNode in="shadowMatrixOuter1"></feMergeNode><feMergeNode in="SourceGraphic"></feMergeNode></feMerge></filter></defs><g fillRule="evenodd" filter="url(#close_svg__a)" transform="translate(-421 -24)"><path d="m439.77 28 1.23 1.23-6.77 6.77 6.77 6.77-1.23 1.23-6.77-6.77-6.77 6.77-1.23-1.23 6.769-6.77L425 29.23l1.23-1.23 6.77 6.769L439.77 28z"></path></g></svg>
        </button>
        <div className="loginsignuptabs">
            <h1>{LoginSignupSet === "Login" ? "Log In" : LoginSignupSet === "SignUp" ? "Sign Up" : "Create New Password"}</h1>
            <div className="loginPopupMain">
                {LoginSignupSet === "Login" ? (<h4>New to this site? <span onClick={() => signupFunction()}>Sign Up</span> </h4>) : 
                LoginSignupSet === "SignUp" ? (<h4>Already a member? <span onClick={() => loginFunction()}>Log In</span> </h4>) :
                (<h4>Please enter your email address</h4>)}
                {!loginWithForm && LoginSignupSet !== "Forgotpassword" ? (
                <React.Fragment>
                   <div className="fbgoglrbnts">
                        {/* <Button className="btn facebook" onClick={(e)=>authWithfacebook(e)}>
                            <span className="iconsn">
                                <img src={FaceBookIcon} alt="FaceBookIcon" />
                            </span>
                            {LoginSignupSet === "Login" ? "Login" : "Sign up"} with Facebook
                        </Button> */}
                        <Button className="btn google" onClick={(e)=>authWithgoogle(e)}>
                            <span className="iconsn">
                                <img src={GoogleIcon} alt="GoogleIcon" />
                            </span>
                            {LoginSignupSet === "Login" ? "Login" : "Sign up"} with Google
                        </Button>
                    </div>
                    <div className="ordevidedst">
                        <span>or</span>
                    </div>    
                    <Button className="btn loginwithemail" onClick={() => setLoginWithForm(!loginWithForm)}>{LoginSignupSet === "Login" ? "Login" : "Sign up"} with Email</Button>
                </React.Fragment>) : (<div className="loginwitemilformwrps">
                    {LoginSignupSet === "Login" ? (<React.Fragment>
                        <div className={errorEmail !== "" ? "form-group error" : "form-group"}>
                            <label>Email</label>
                            <input type="email" className="d-none" />
                            <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off"/>
                            {errorEmail && <div className="danger-colorset">{errorEmail}</div>}
                        </div>
                        <div className={errorPassword !== "" ? "form-group error" : "form-group"}>
                            <label>Password
                                <div className="passwordnotewrapper">
                                    <ol>
                                        {/* Minimum 8 characters. */}
                                        <li>The alphabet must be between [a-z]</li>
                                        <li>At least one alphabet should be of Upper Case [A-Z]</li>
                                        <li>At least 1 number or digit between [0-9].</li>
                                        <li>At least 1 character from [ @ $ ! % * ? & ].</li>
                                    </ol>
                                    <Image src={InfoIcon} alt="info icon" />
                                </div>
                            </label>
                            <input type="password" className="d-none" style={{ display: "none" }} />
                            <input type={showPassword ? "text" : "password"} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="new-password"/>
                            <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>}
                            </span>
                            {errorPassword && <div className="danger-colorset">{errorPassword}</div>}
                        </div>
                        <h5 className="forgotpassword" onClick={() => forgotpassFunction()}>Forgot password?</h5>
                        <Button className="btn loginbtn" onClick={() => LoginInBtn()}>Log In</Button>
                    </React.Fragment>) : LoginSignupSet === "SignUp" ?
                    (<React.Fragment>
                        <div className={errorEmail !== "" ? "form-group error" : "form-group"}>
                            <label>Email</label>
                            <input type="email" className="d-none"  />
                            <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value.trim())} autoComplete="off"/>
                            {errorEmail && <div className="danger-colorset">{errorEmail}</div>}
                        </div>
                        <div className={errorPassword !== "" ? "form-group error" : "form-group"}>
                            <label>Password
                                <div className="passwordnotewrapper">
                                    <ol>
                                        {/* Minimum 8 characters. */}
                                        <li>The alphabet must be between [a-z]</li>
                                        <li>At least one alphabet should be of Upper Case [A-Z]</li>
                                        <li>At least 1 number or digit between [0-9].</li>
                                        <li>At least 1 character from [ @ $ ! % * ? & ].</li>
                                    </ol>
                                    <Image src={InfoIcon} alt="info icon" />
                                </div>
                            </label>
                            <input type="password" className="d-none" style={{ display: "none" }} />
                            <input type={showPassword ? "text" : "password"} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="new-password" />
                            <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>}
                            </span>
                            {errorPassword && <div className="danger-colorset">{errorPassword}</div>}
                        </div>
                        <Button className="btn loginbtn" onClick={() => SignUpBtn()}>Sign Up</Button>
                    </React.Fragment>) :
                    (<React.Fragment>
                        <div className={errorEmail !== "" ? "form-group error" : "form-group"}>
                            <label>Email</label>
                            <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value.trim())} autoComplete="off" />
                            {errorEmail && <div className="danger-colorset">{errorEmail}</div>}
                        </div>
                        <Button className="btn loginbtn" onClick={() => ForgotPasswordFnct()}>Create Password</Button>
                    </React.Fragment>)}

                    {LoginSignupSet !== "Forgotpassword" && (<React.Fragment>
                         <div className="ordevidedst ordevidedstmrgin">
                            <span>or {LoginSignupSet === "Login" ? "log in" : "Sign Up"} with</span>
                        </div>
                        <div className="socillinks">
                            {/* <div>
                                <a href="" onClick={(e)=>authWithfacebook(e)}>
                                    <img src={FaceBookIcon} className="FaceBookIcon" alt="FaceBookIcon" />
                                </a>
                            </div> */}
                            <div>
                                <a href="" onClick={(e)=>authWithgoogle(e)}>
                                    <img src={GoogleIcon} alt="GoogleIcon" />
                                </a>
                            </div>
                        </div> 
                    </React.Fragment>)}
                </div>)}
            </div>
        </div>
    </div>)
}

export default LoginSignupModal;