/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import DefaultProfile from "../../assets/img/dummyimage.png";
import ProfileLoader from "../../assets/img/spinner_transferent.svg";
import { PROFILE_UPDATE_API_URL, PROFILE_UPLOAD_API_URL, GET_MY_PRODUCT, ABOUT_URL } from '../Shared/constant';
import CustomMendotoryMsg from "../common/CustomMendotoryMsg";
import CountrySelectInput from "../common/CountrySelectInput/CountryMobileInput";
import SucessIcon from "../../assets/img/success.svg";
import CommonModal from "../common/CommonModal";
import AuthLayout from "../Shared/AuthLayout";
import { useSelector, useDispatch } from "react-redux";
import { updateUser, updateProfilePic, accountNavigate, GetMyProducts, getAccessToken, checkLogin, getUser } from "../../redux/actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PreviewImageFiles from "../common/PreviewImageFiles";

const Account = () => {
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const { accessToken, profiledata, accountUrl, myproducts } = useSelector((state) => state.allReducers);
    const [ CustomError, setCustomError ] = useState(false);
    const [ fname, setFname ] = useState((profiledata?.first_name === null || profiledata?.first_name === "") ? "" : profiledata?.first_name);
    const [ lname, setLname ] = useState((profiledata?.last_name === null || profiledata?.last_name === "") ? "" : profiledata?.last_name);
    const [ profileUpload, setProfileUpload ] = useState(profiledata?.user_avatar_path === null ? "" : profiledata?.user_avatar_path);
    const [ MobileNo, setMobileNo ] = useState({
        countrycode: (profiledata?.phone === null || profiledata?.phone === "") ? "1" : profiledata?.phone?.split(" ")[0],
        number: (profiledata?.phone === null || profiledata?.phone === "") ? "" : parseInt(profiledata?.phone?.split(" ")[1]),
    });
    const [ commonPopup, setCommonPopup ] = useState({
        open: false,
        title: "",
        description: "",
        buttontitle: "" 
    });
    const [ fileUploadLoader, setFileUploadLoader ] = useState(false);
    const [ MobileError, setMobileError ] = useState("");
    
    useEffect(() => {
        // get my product
        window.axios.get( GET_MY_PRODUCT, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            if (result.data) {
                dispatch(GetMyProducts(result?.data?.data));
            }
        }).catch(function (result) {
            dispatch(GetMyProducts([]));
            if(result?.response?.data?.detail === "Token expired.") {
                AccessTokenCheckLogout();
            };
        });
    }, [ ]);

    // code updated
    useEffect(() => {
        setFname((profiledata?.first_name === null || profiledata?.first_name === "") ? "" : profiledata?.first_name);
        setLname((profiledata?.last_name === null || profiledata?.last_name === "") ? "" : profiledata?.last_name);
        setProfileUpload(profiledata?.user_avatar_path === null ? "" : profiledata?.user_avatar_path);
        setMobileNo({
            ...MobileNo, 
            countrycode: (profiledata?.phone === null || profiledata?.phone === "") ? "1" : profiledata?.phone?.split(" ")[0],
            number: (profiledata?.phone === null || profiledata?.phone === "") ? "" : parseInt(profiledata?.phone?.split(" ")[1]),    
        });
    }, [ profiledata ]);

    // if accessToken expire then page will logut and redirect to home page 
    const AccessTokenCheckLogout = () => {
        setTimeout(() =>  Navigate(ABOUT_URL), 200);
        localStorage.removeItem("accessToken");
        dispatch(getAccessToken(""));
        dispatch(checkLogin("no"));
        dispatch(getUser(""));
    };

    // Discard Account Modal Function
    const DiscardAccount = () => {
        setCommonPopup({...commonPopup, 
            open: !commonPopup?.open, 
            title: "Discard Changes?", 
            description: "Are you sure you want to discard the changes you've made?", 
            buttontitle: "Discard"
        })
    };

    // Discard field modal function
    const DiscardAccountData = () => {
        setCommonPopup({...commonPopup, open: false});
        setFname((profiledata?.first_name === null || profiledata?.first_name === "") ? "" : profiledata?.first_name);
        setLname((profiledata?.last_name === null || profiledata?.last_name === "") ? "" : profiledata?.last_name);
        setMobileNo({...MobileNo, 
            countrycode: (profiledata?.phone === null || profiledata?.phone === "") ? "1" : profiledata?.phone?.split(" ")[0],
            number: (profiledata?.phone === null || profiledata?.phone === "") ? "" : parseInt(profiledata?.phone?.split(" ")[1])
        })
    };
    
    // profile update function
    const ProfileUpdate = () => {
        document.getElementById("accountflag")?.classList?.remove("active");
        if(fname !== "" && lname !== "" && MobileNo?.number?.toString()?.length === 10) {
            const jsonData = JSON.stringify({ first_name: fname, last_name: lname, phone: MobileNo?.countrycode + " " + MobileNo?.number});
            window?.axios?.post(PROFILE_UPDATE_API_URL, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                }
            }).then(function (result) {
                dispatch(updateUser(result?.data?.data));
                setMobileError("");
                toast.success(result.data.msg);
            })  
            .catch(function (result) {
                toast.error(result.response.data.error);
            });
        } else {
            setCustomError(true);
            if(MobileNo?.number === "") {
                setMobileError("Phone Number cannot be empty");
            } else if(MobileNo?.number?.toString()?.length) {
                setMobileError("Please enter 10 digit number");
            } else {
                setMobileError("");
            }
        }
    }

    // upload avatar image
    const UploadAvatarImage = (e) => {
        setFileUploadLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("avatar", files, files.name);

        window.axios.post( PROFILE_UPLOAD_API_URL, formData, {
            headers: {
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            if (result.data) {
                setProfileUpload(result?.data?.data?.user_avatar_path);
                dispatch(updateProfilePic(result?.data?.data?.user_avatar_path));
                setTimeout(() => setFileUploadLoader(false), 100);
            }
        }).catch(function (result) {
            setTimeout(() => setFileUploadLoader(false), 100);
        });
    };

    // donwload file
    const DownloadFile = (file) => {
        fetch(process.env.REACT_APP_BASE_URL+file?.product_file_path,{
            method: 'GET',
		headers: {
            'Authorization': 'Bearer ' + accessToken,
		},}).then(response => response.blob())
            .then(blob => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = file.name;
            link.click();
        })
        .catch(console.error);
    };

    // responsive tab change
    const selctResponsiveTab = (value) => {
        if(value === "My Books") {
            dispatch(accountNavigate("my-books"));
        } else {
            dispatch(accountNavigate("my-account"));
        }
    };

    return(<AuthLayout><section className="accoutntwrapper">
        <div className="container custom_container_new">
            <div className="myacountsatabswrps">
                <div className="row" style={{ margin: "auto" }}>
                    <div className="col-md-4">
                        <div className="myaccountprofilepic">
                            <div className="profileacountset">
                                <div className="acctprofile">
                                    {profileUpload === "" ? <img src={DefaultProfile} alt="profile" /> : <img src={profileUpload} onError={(e)=> {e.target.src = DefaultProfile}} alt="profile" />}
                                    <label className="uploadfileovarlay">
                                        <div className="svgwrp">
                                            <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24"><path fillRule="evenodd" d="M14.267,4 C14.801,4 15.299,4.287 15.566,4.75 L15.566,4.75 L16.144,5.75 C16.233,5.904 16.399,6 16.577,6 L16.577,6 L19.5,6 C20.327,6 21,6.673 21,7.5 L21,7.5 L21,17.5 C21,18.327 20.327,19 19.5,19 L19.5,19 L4.5,19 C3.673,19 3,18.327 3,17.5 L3,17.5 L3,7.5 C3,6.673 3.673,6 4.5,6 L4.5,6 L7.435,6 C7.609,6 7.773,5.907 7.863,5.758 L7.863,5.758 L8.483,4.727 C8.752,4.278 9.245,4 9.769,4 L9.769,4 Z M14.267,5 L9.769,5 C9.594,5 9.43,5.093 9.34,5.242 L9.34,5.242 L8.72,6.273 C8.451,6.722 7.958,7 7.435,7 L7.435,7 L4.5,7 C4.224,7 4,7.225 4,7.5 L4,7.5 L4,17.5 C4,17.775 4.224,18 4.5,18 L4.5,18 L19.5,18 C19.776,18 20,17.775 20,17.5 L20,17.5 L20,7.5 C20,7.225 19.776,7 19.5,7 L19.5,7 L16.577,7 C16.043,7 15.545,6.713 15.278,6.25 L15.278,6.25 L14.7,5.25 C14.611,5.096 14.445,5 14.267,5 L14.267,5 Z M11.9996,7.9999 C13.2656,7.9999 14.4706,8.6099 15.2236,9.6329 C15.9876,10.6719 16.1996,11.9939 15.8046,13.2609 C15.4326,14.4579 14.4576,15.4329 13.2606,15.8049 C12.8426,15.9349 12.4176,15.9989 11.9996,15.9989 C11.1516,15.9989 10.3286,15.7349 9.6336,15.2229 C8.6106,14.4699 7.9996,13.2659 7.9996,11.9999 C7.9996,9.7939 9.7946,7.9999 11.9996,7.9999 Z M11.9996,8.9999 C10.3456,8.9999 8.9996,10.3459 8.9996,11.9999 C8.9996,12.9489 9.4586,13.8529 10.2256,14.4169 C11.0056,14.9919 12.0026,15.1479 12.9636,14.8499 C13.8506,14.5729 14.5736,13.8519 14.8496,12.9639 C15.1486,12.0029 14.9916,11.0059 14.4176,10.2259 C13.8526,9.4579 12.9496,8.9999 11.9996,8.9999 Z"></path></svg>
                                        </div>
                                        <input type="file" onChange={UploadAvatarImage} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                    </label>
                                    {fileUploadLoader === true && <div className="imageloader"><img src={ProfileLoader} alt="loader"/></div>}
                                </div>
                                <h4>{(profiledata.first_name === null && profiledata.last_name === null) ? profiledata?.email : 
                                    (profiledata.first_name !== null ? profiledata.first_name : "") + " " + (profiledata.last_name !== null ? profiledata.last_name : "")}</h4>
                            </div>
                        </div>
                        {window.innerWidth > 767 ? (<div className="accountsRouting">
                            <a className={accountUrl === "my-books" ? "accountsRoutingLink active" : "accountsRoutingLink"} onClick={() => dispatch(accountNavigate("my-books"))}>My Posters</a>
                            <a className={accountUrl === "my-account" ? "accountsRoutingLink active" : "accountsRoutingLink"} onClick={() => dispatch(accountNavigate("my-account"))}>My Account</a>
                        </div>) : (<select className="selectAccountssct" onChange={(e) => selctResponsiveTab(e.target.value)}>
                            <option value="My Books">My Posters</option>
                            <option value="My Account">My Account</option>
                        </select>)}
                    </div>
                    <div className="col-md-8">
                        {accountUrl === "my-account" ? (<div className="myaccountwrps">
                            <div className="myaccountwrpsheader">
                                <div className="headertitle">
                                    <h4>My Account</h4>
                                    <p>View and edit your personal info below.</p>
                                </div>
                                <div className="bttngroup">
                                    <Button className="btn discardbtn" onClick={() => DiscardAccount()}>Discard</Button>
                                    <Button className="btn updteinfobtn" onClick={() => ProfileUpdate()}>Update Info</Button>
                                </div>
                            </div>
                            <div className="accountwrps">
                                <h4>Account</h4>
                                <h5>Update your personal information.</h5>
                                <div className="paymentformsWraps paymentformsWrapsaccount">
                                    <div className="rowcustom rowcustom-col-2">
                                        <div className={CustomError && fname === "" ? "form-group error" : "form-group"}>
                                            <label>Login Email:</label>
                                            <div className="emailset">{profiledata?.email}</div>
                                            <div className="noteemail">Your Login email can't be changed</div>
                                        </div>
                                    </div>
                                    <div className="rowcustom rowcustom-col-2">
                                        <div className={CustomError && fname === "" ? "form-group error" : "form-group"}>
                                            <label>First Name</label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" value={fname} onChange={(e) => setFname(e.target.value.trim())} placeholder="Enter First Name" />
                                                {fname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                                {CustomError && fname === "" && <CustomMendotoryMsg value={fname} label={"First name"} />}
                                            </div>
                                        </div>
                                        <div className={CustomError && lname === "" ? "form-group error" : "form-group"}>
                                            <label>Last Name</label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" value={lname} onChange={(e) => setLname(e.target.value.trim())} placeholder="Enter Last Name" />
                                                {lname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                                {CustomError && lname === "" && <CustomMendotoryMsg value={lname} label={"Last name"} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rowcustom rowcustom-col-2">
                                        <div className={MobileError !== "" ? "form-group error" : "form-group"}>
                                            <label>Phone</label>
                                            <div className="form-groupfiled">
                                                <CountrySelectInput 
                                                    MobileNo={MobileNo}
                                                    setMobileNo={setMobileNo}
                                                    id={"accountflag"}
                                                />
                                                {MobileNo?.number?.toString()?.length === 10 && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                                {MobileError !== "" && <div className="danger-color">{MobileError}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rowcustomright" >
                                        <Button className="btn discardbtn" onClick={() => DiscardAccount()}>Discard</Button>
                                        <Button className="btn updteinfobtn" onClick={() => ProfileUpdate()}>Update Info</Button>
                                    </div>
                                </div>
                            </div>
                        </div>) : (<div className="myaccountwrps">
                        <div className="myaccountwrpsheader">
                            <div className="headertitle">
                                <h4>Your Posters</h4>
                            </div>
                            <div className="poserrbooksrow">
                                {Array?.from(myproducts)?.length > 0 ? Array?.from(myproducts)?.map((elm) => {
                                    return elm && elm?.map((product , index) => {
                                        return(<div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: "0px 5px" }} key={index}>
                                        <div className="posterblogswrp">
                                            <PreviewImageFiles type="product" url={product?.product_img_path} title={product.name}/>
                                            <div className="posterblogswrpdetails">
                                                <h5>{product?.name}</h5>
                                                <div className="postaddcart">
                                                    <h4>${product?.price}</h4>
                                                    <span onClick={() => DownloadFile(product)}>
                                                        Download
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                    })
                                }) : (<h5 className="noPosterTitle">No Poster Available</h5>)}
                            </div>
                        </div>
                    </div>)}
                    </div>
                </div>
            </div>
        </div>
        {commonPopup?.open && (<CommonModal DiscardAccountData={DiscardAccountData} commonPopup={commonPopup} setCommonPopup={setCommonPopup} />)}
        {commonPopup?.open && (<div className="ModalBackground"></div>)}
    </section></AuthLayout>)
}

export default Account;